<template>
  <div class="deleteaccount box">
    <h2 class="title is-2">{{ $t('account.delete_account') }}</h2>
    <form @submit.prevent="deleteAccount()">
      <b-button size="is-large" class="is-primary" native-type="submit">{{ $t('account.delete_account') }}</b-button>
      <p v-if="success" class="has-text-success">{{ $t('account.delete_account_success') }}</p>
      <p v-if="error" class="has-text-danger">{{ $t(errormsg) }}</p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'delete_account',
  data(){
    return {
      error: false,
      success: false,
      errormsg: "",
    };
  },
  methods: {
    deleteAccount() {
      this.error = false;
      var data = { token: this.$route.params.token };
      this.post('/delete_account', data).then(data => {
        this.error = false;
        this.success = true;
        this.$store.dispatch('logout');
        setTimeout(() => { window.location.href = '/'; }, 2000);
      }).catch(err => {
        this.error = true;
        this.errormsg = err;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.deleteaccount{
  padding: 40px;
  max-width: 600px;
  margin: 40px auto;
}
</style>
